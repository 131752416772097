//Shop Top Bar
.shop-breadcrumb {
  .action-btn .btn {
    height: 36px;
    @include ofs(12px, lh(12px, 15px), 500);
    @extend .color-gray;
    border-radius: 3px;

    i {
      @extend .color-primary;
    }

    &.btn-primary,
    &.btn-primary i {
      color: white;
    }
  }
}

.shop-search .user-member__form svg {
  @extend .color-light;
}

//Product Grid
.product_page--grid,
.product_page--list {

  .project-category p {
    margin-bottom: 0;
    margin-right: 20px;

    @include xs {
      margin: 8px 20px 8px;
    }
  }

  .project-top-left {
    @media (max-width: 414px) {
      margin-bottom: 5px;
    }
  }

  .project-top-wrapper {
    justify-content: space-between;

    @include sm {
      justify-content: center;
    }
  }

  .shop_products_top_filter {
    margin-bottom: 30px;

    @include sm {
      margin-bottom: 20px;
    }
  }
}



.project-search {
  &.order-search{
    @include sm {
      margin: 10px 50px;
      @include ssm{
        margin: 0;
      }
    }
  }
  @include md {
    display: flex;
    justify-content: center;

    .user-member__form {
      min-width: 370px;

      @include sm {
        min-width: 350px;
      }

      @include xs {
        min-width: 250px;
        max-width: 350px;
      }
      @include xxs{
        min-width: 250px !important;
      }
    }
  }
}

.product {
  transition: $transition-base;

  .product-item__button {
    button {
      height: 36px;
      margin: 5px;
    }
  }

}

.product--grid {
  .product-item__button {
    margin: -5px;
  }
  &:hover {
    transform: scale(1.1);

    @include xl{
      transform: unset;
    }
  }
}

.product-item {
  @include e(body) {
    .card-title {
      h6 {
        @include ofs(15px, lh(15px, 20px), 500);
        margin-bottom: 15px;
        text-transform: capitalize;
      }

      a {
        @extend .color-dark;
      }
    }

    .product-desc-price {
      @include ofs(14px, lh(14px, 20px), 600);
      @extend .color-primary;
    }

    .product-price {
      @include ofs(14px, lh(14px, 20px), 400);
      @extend .color-extra-light;
      text-decoration: line-through;
      margin-right: 10px;
      margin-left: 10px;
    }

    .product-discount {
      @include ofs(12px, lh(12px, 22px), 500);
      @extend .color-secondary;
    }
  }

  @include e(button) {

    button {
      height: 34px;
      @include ofs(12px, lh(12px, 15px), 500);
      @extend .color-gray;
      text-transform: capitalize;
      @extend .border-light;
      border-radius: 4px;

      svg {
        width: 13px;
      }

      &.btn-primary {
        color: white;
        box-shadow: 0px 3px 5px #20C9970D;
      }
    }
  }
}

//Product List

.product--list {
  .product-item {
    .product-item__image {
      width: 300px;
      height: 200px;

      @include xl {
        width: 300px;
        height: unset;

        img {
          height: unset;
        }
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
  }

  .like-icon {
    top: 0;

    @include lg {
      right: -10px;
    }
  }
}

.product--list .product-item {
  padding: 20px;
    .product-item__title{
      p{
        @include ofs(15px, lh(15px, 24px), 400);
        @extend .color-gray;
        margin-top:17px;
      }
    }

  @include media-breakpoint-up(xl) {
    .product-item__title {
      flex: 1.5;
      padding: 0 30px;
    }

    .product-item__content {
      flex: 1;
      padding: 0 30px;
    }
  }

  @include lg {
    .product-item__title {
      flex: 1.5;
      padding: 0 15px;
      padding-right: 0;
    }

    .product-item__content {
      flex: 1;
      padding: 0 15px;
    }

    .product-item__image {
      width: 307px;
      height: auto;
    }
  }

  @include ssm {
    .like-icon {
      top: 50%;
      right: -28px;
      transform: translate(-50%, -50%);
    }

    .product-item__image {
      width: 100%;
      height: 100%;
    }

    .product-item__title {
      padding-left: 0;
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .product-item__content {
      padding: 0;
    }
  }
}

.product--list .product-item,
.product--list .product-item__body {
  @include media-breakpoint-up(sm) {
    display: flex;
  }
}

.product--list .product-item__body {
  .product-item__button {
    margin-top: 30px;

    @include md {
      margin-top: 20px;
    }
  }

  @include media-breakpoint-up(md) {

    .product-item__button button {
      width: 132px;
      height: 38px;
    }
  }

  .card-title {
    @include ofs(18px, null, null);
    margin-bottom: 0;
    text-transform: capitalize;
  }

  .card-title+p {
    @include ofs(15px, lh(15px, 25px), 400);
    @extend .color-gray;
    margin-top: 13px;
  }
}

//Star Rating
.stars-rating {
  line-height: normal;

  .star-icon {
    @include ofs(13px, null, null);

    &.active {
      @extend .color-warning;
    }

    &.inactive {
      color: #C6D0DC;
    }
  }

  span {
    &.active {
      @extend .color-warning;
    }

    &.inactive {
      color: #C6D0DC;
    }
  }

  @include e(point) {
    @include ofs(12px, lh(12px, 15px), 500);
    @extend .color-dark;
    margin-left: 6px;
    margin-right: 10px;

  }

  @include e(review) {
    @include ofs(12px, lh(12px, 15px), 400);
    @extend .color-light;
  }
}

.product-ratings {
  .checkbox-text {
    @include ofs(13px, null, 400);
    @extend .color-light;
    text-transform: lowercase;
  }

  .stars-rating .active {
    font-size: 14px;
  }
}

//Like Icon
.like-icon {
  position: absolute;
  @include ofs(15px, lh(15px, 27px), 400);
  @extend .wh-34;
  background-color: white;
  @extend .content-center;
  box-shadow: 0px 5px 10px #9299B81A;
  right: 15px;
  top: 15px;
  border-radius: 50%;

  button {
    @extend .color-light;
    border: none;
    box-shadow: none;
    background: transparent;

    .icon {
      @include ofs(16px, null, null);

      &.las {
        @extend .color-danger;
      }
    }
  }
}

//Shop Sidebar
.bookmark-page,
.products_page {

  .columns-1,
  .columns-2 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .columns-1 {
    @media (min-width: 1600px) {
      flex: 0 0 20.70%;
      max-width: 20.70%;
    }

    @include cMq(1200px, 1599px) {
      max-width: 30%;
      flex: 0 0 30%;
    }

    @include media-breakpoint-down(xs) {
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
  }

  .columns-2 {
    @media (min-width: 1600px) {
      flex: 1 0 79.30%;
      max-width: 79.30%;
    }

    @include cMq(1200px, 1599px) {
      max-width: 70%;
      flex: 0 0 70%;
    }
  }
}

.bookmark-page__list,
.product-page-list {
  @media (min-width: 1600px) {
    .cus-xl-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }

}

.bookmark-page__list .cus-xl-3 {
  padding: 0 12.50px;
}

.products_page {
  .widget {
    background-color: #fff;
    box-shadow: 0px 5px 20px #9299B808;
    border-radius: 10px;
    display: inline-block;
    position: relative;
    width: 100%;
  }

  .category_sidebar {
    padding: 25px;

    @include xl {
      padding: 15px;
    }

    @include lg {
      padding: 25px;
    }
  }

  .widget-header-title svg {
    @extend .color-light;
    width: 16px;
    height: 16px;
    margin-right: 12px;
  }
}

.product-sidebar-widget {
  .widget_title {
    h6 {
      @include ofs(15px, lh(15px, 20px), null);
    }
  }
}

//Category Widget

.product-ratings,
.product-brands,
.product-category {
  ul li {
    text-transform: capitalize;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    label {
      width: 100%;
    }

    .item-numbers {
      float: right;
      @extend .color-light;
      @include ofs(12px, null, 400);
      margin-top: 2px;
    }
  }
}

.product-brands {
  .checkbox-text {
    font-size: 14px;
    @extend .color-gray;
  }
}

.product-ratings {
  .checkbox-text {
    width: 100%;
    margin-left: 10px;
  }
}

//Price Ranges
#price-range {
  width: 100%;
  margin: 5% auto;

  .price-value {
    @include ofs(14px, lh(14px, 20px), 500);
    @extend .color-dark;
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  background-color: #fff;
  border-radius: 50%;
}


.ui-slider-horizontal {
  .ui-slider-handle {
    top: -7px;
  }

  height: 3px;
  border: 0;
  background-color: rgba($primary, 0.20);
  border-radius: 10px;
}


.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  box-sizing: border-box;
  outline: 0;
}

.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}

.ui-slider {
  position: relative;
  text-align: left;

  .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 16px;
    height: 16px;
    cursor: default;
    touch-action: none;
    background: white;
    border: 2px solid #5F63F2;
  }

  .ui-slider-range {
    position: absolute;
    z-index: 1;
    font-size: .7em;
    display: block;
    border: 0;
    background-position: 0 0;
    @extend .bg-primary;
  }
}

//Product Details
.product-details {
  @include e(availability) {
    .title {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 7px;
      }

      p {
        @include ofs(14px, lh(14px, 20px), 500);
        @extend .color-dark;
        margin-bottom: 0;
        text-transform: capitalize;
        flex-basis: 65px;
        margin-bottom: 0;

      }

      span {
        margin-left: 30px;
        @include ofs(14px, lh(14px, 20px), 500);

        &.stock {
          @extend .color-success;
        }

        &.free {
          font-weight: 400;
          @extend .color-gray;
        }
      }
    }
  }

  .product-item__body {
    flex-basis: 43%;
  }

  .product-item__body .product-desc-price {
    display: flex;
    align-items: center;
    @include ofs(22px, lh(22px, 20px), 500);
    margin-top: 30px;
    margin-bottom: 10px;

    sub {
      font-size: 14px;
      @extend .color-light;
    }
  }

  .product-details-brandName {
    @include ofs(13px, lh(13px, 20px), 400);
    @extend .color-light;
    display: flex;
    margin-top: 10px;

    span {
      @extend .color-dark;
      font-weight: 500;
      margin-left: 6px;
    }
  }

  .product-item__body .product-price {
    @include ofs(16px, lh(16px, 20px), 400);
  }

  .product-deatils-pera {
    @include ofs(15px, lh(15px, 25px), 400);
    @extend .color-gray;
    margin: 25px 0;
  }

  .product-item__button {
    @include sm {
      flex-flow: column;
      align-items: flex-start;
    }

    button {
      color: white;
      height: 44px;
      padding: 14px 30px;
      border-radius: 6px;
      @include ofs(14px, null, 500);

      svg {
        width: 15px;
      }
    }

    .like-icon {
      position: unset;
      box-shadow: 0px 5px 15px #9299B826;
      width: 44px;
      height: 44px;
      margin-right: 10px;

      button {
        @extend .color-light;
        padding: 0;
      }
    }
  }
}

@include md {

  .project-tap .nav-link {
    font-size: 13px;
    padding: 0 12px;

    @include sm {
      padding: 0 7.5px;
    }
  }
}

.project-top-left {
  @include xxl {
    margin-bottom: 20px;
  }

  @include lg {
    margin-bottom: 20px;
  }
}

.project-top-left,
.project-top-right {
  @include xxl {
    width: 100%;
    justify-content: space-between;
  }

  @include sm {
    justify-content: center;

  }
}

.shop_products_top_filter {

  .project-top-left {
    @include md {

      .project-search,
      .project-result-showing {
        width: 100%;
      }
    }
  }

  .project-top-right {
    @include md {

      .project-category,
      .project-icon-selected {
        width: 100%;
      }

      .project-category {
        justify-content: center;
      }
    }
  }
}

//Product Details Social Icon
.product-deatils__social li {
  a {
    @extend .color-gray;
    font-size: 16px;
  }

  &:not(:first-child) {
    a {
      margin-left: 10px;
    }
  }

}

.product-item__image {

  .list-thumb-gallery {
    padding: 0;
    list-style-type: none;
    padding-top: 1rem;
    list-style-type: none;

    >li {
      margin-bottom: 1rem;
      width: 80px;
      height: 80px;

      &:not(:last-child) {
        margin-right: 10px;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }

    }
  }

  .carousel-control-prev,
  .carousel-control-next,
  .carousel-indicators {
    display: none;
  }

  .carousel-item {
    width: 580px;
    height: 450px;
    max-width: 100%;

    @include xxl {
      width: 100%;
      height:400px;
    }
    @include lg{
      height: 350px;
    }
    @include md{
      height: 100%;
    }

    img {
      border-radius: 10px;
      width: 100%;
      height: 450px;

      @include xl {
        height:100%;
      }
    }
  }
}



//Product Details Quantity

.quantity {
  display: flex;
  align-items: center;

  .input {
    width: 38px;
    padding: 0;
    margin: 0;
    text-align: center;
    border: none;
    background: transparent;
    @include ofs(14px, lh(14px, 20px), 500);
    @extend .color-dark;
    -webkit-appearance: none;
    -moz-appearance:textfield;
    
  }

  .bttn {
    border: 1px solid $border-color;
    border-radius: 10px;
    @extend .content-center;
    @extend .color-gray;
    text-decoration: none;
    @include ofs(20px, null, null);

    &:hover {
      background: rgba($primary, 0.10);
      @extend .color-primary;
    }
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.product-quantity {
  margin-top: 30px;

  .bttn {
    width: 38px;
    height: 38px;
  }

  p {
    margin-bottom: 0;
    margin-right: 40px;
  }

  button+span {
    margin-left: 15px;
  }

}

//Breadcrumb
.shop-breadcrumb {
  @include sm {
    .breadcrumb-main {
      flex-flow: column;
      align-items: center;
      margin-bottom: 19px;

      h4 {
        margin-bottom: 6px;
      }
    }
  }
}

.product-item__content{
  .product-item__button{
    margin-bottom: -5px;
    .product-item__action{
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .product-deatils__social{
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}